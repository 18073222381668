<template>
  <div>
    <div class="center" v-if="showLoading">
		  <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	  </div>
    <div v-else>
      <headerSection></headerSection>
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
            <div class="x-promotion-index" style="padding-top:100px;">
                <div class="container">
                    <div class="row">
                        <div class="col-12" style="text-align: center;">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                <h1 class="-title" style="font-size:20px;color:#fbc844;">เงื่อนไขฝาก ({{countQTY}}/{{QTY}})</h1>
                                </div>
                            </div>
                            <p class="-description" style="color: #fff;">**เงื่อนไขฝากทุกๆ 300 ได้รับสิทธิ์ทันที เฉพาะวันที่หวยออก**</p>
                        </div>
                    </div>
                </div>
            <!-- 'height':'100vh'-->
              <div class="container">
                <div class="x-wrapper-right-container"></div>
                  <div id="contact_gl" class="section" style="padding-top: -100px !important;">
                        <div style="margin-top: 5px; margin-left: 16px;">
                            <div style="margin-top: 5px; display: flex; margin-bottom: 15px;">
                                <button v-if="isShow" type="button" @click="confirm()" :disabled="isDisabled" class="btn2">{{this.buttonLabel}}</button>
                            </div>
                        </div>
                        <div class="rowClass" v-for="index in rows" :key="index">
                            <a href="#" :class="[huays[renderNumber(index,0)] ? 'active' : '', 'button1']" style="color:#FFFFFF;" @click="selectNumber(renderNumber(index,0))">{{renderNumber(index,0)}}</a>
                            <a href="#" :class="(huays[renderNumber(index,1)])?'button1 active':'button1'" style="color:#FFFFFF;" @click="selectNumber(renderNumber(index,1))">{{renderNumber(index,1)}}</a>
                            <a href="#" :class="(huays[renderNumber(index,2)])?'button1 active':'button1'" style="color:#FFFFFF;" @click="selectNumber(renderNumber(index,2))">{{renderNumber(index,2)}}</a>
                            <a href="#" :class="(huays[renderNumber(index,3)])?'button1 active':'button1'" style="color:#FFFFFF;" @click="selectNumber(renderNumber(index,3))">{{renderNumber(index,3)}}</a>
                            <a href="#" :class="(huays[renderNumber(index,4)])?'button1 active':'button1'" style="color:#FFFFFF;" @click="selectNumber(renderNumber(index,4))">{{renderNumber(index,4)}}</a>
                        </div>
                        <div style="margin-top: 15px; margin-left: 16px;">
                            <div style="margin-top: 5px; display: flex; margin-bottom: 24px;">
                                <button v-if="isShow" type="button" @click="confirm()" :disabled="isDisabled" class="btn2">{{this.buttonLabel}}</button>
                            </div>
                        </div>
                        <p class="-description" style="color:#fff; center;">ทายถูกรับ 500 บาท
📣เงื่อนไขเข้าร่วมกิจกรรม   📣

1. ส่งสลิปเงินเฉพาะ วันที่ 15 ธ.ค. 67 ยอด 300 บาท ขึ้นไป
2.  1 สลิปสามารถรับสิทธ์ 1ครั้งในการท้ายผล
3.ทายรางวัลเลข 2 ตัวบนได้ สูงสุด ไม่จำกัดครัง 
4.ปิดการทายพล 15:00 ของวันที่ 16 ธ.ค. 67
5.มีรายการเล่น 1 เทิร์น ก่อนเวลา 16:00 ของวันที่ 15 ธ.ค.  67
6.รางวัลเครดิตฟรีจะถูกเพิ่มให้ทายภายในเวลา 19:00 ของวันที่ 15 ธ.ค.  67</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <footerSection></footerSection>
      </div>
    </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/loading.json'
import moment from 'moment-timezone'
const axios = require('axios').default
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Gift',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue'),
    lottie: Lottie
  },
  created () {
    const user = localStorage.getItem('userData')
    if (user) {
        const _user = JSON.parse(user)
        this.$store.dispatch('user/setUser', _user).then(()=>{
            if(Number(moment().tz("Asia/Bangkok").format('D')) === 1 || Number(moment().tz("Asia/Bangkok").format('D')) === 16){
              if(Number(moment().tz("Asia/Bangkok").format('H')+''+moment().tz("Asia/Bangkok").format('mm')) <= 1520){
                this.calculate();
                this.isShow = true;
              }else{
                 this.calculate();
              }
            }else{
              this.QTY = 0;
              this.countQTY = 0;
            }
        }).catch(err => { console.error(err) })
    } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
    }
    const animateSplashScreen = animateSantaWalk.default
    this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  mounted () {
        this.animSplashScreen.play()
        setTimeout(() => {
            this.showLoading = false
            this.animSplashScreen.stop()
            // setTimeout(() => {
            //   this.canvas = document.getElementById('canvas')
            //   this.repaint(this.angle)
            // }, 500)
        }, 3500)
      //this.getGames()
  },
  data () {
    return {
      isShow: false,
      huays:[],
      rows:20,
      logoLeft: '',
      logoRight: '',
      angle: 0,
      gameKey: 'spin',
      showLoading: true,
      pictureUrl: '',
      code: '',
      games_web: [],
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
        classes: 'fadeIn',
        duration: 5000,
        iteration: 'infinite'
      },
      buttonLabel:'ยืนยันแทงหวย',
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext: '',
      credit: '',
      save: false,
      canvas: null,
      frame: null,
      wheels: null,
      sections: [],
      sectionspoint: [],
      colors: [],
      percent: [],
      isDisabled: false,
      QTY:'loading',
      countQTY:'loading',
    }
  },
  methods: {
    calculate(){
        firebase.firestore().collection('krungsri').where('date', '>=', moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 00:00:00').where('type','==','ฝาก').where('status','==','ดำเนินการแล้ว').where('userkey','==',this.user.key).get().then((snapshot)=>{
            let deposit = 0;
            snapshot.forEach((doc)=>{
                deposit += (Number(doc.data().qty.toString().replace(',','')));
            });
            const qty = Math.floor(deposit/300);
            this.QTY = qty;
            if(qty){
            firebase.firestore().collection('huays').doc(this.user.key+'-'+moment().tz("Asia/Bangkok").format("YYYY-MM-DD")).get().then((doc)=>{
                if(doc.exists){
                    if(doc.data().huays){
                        for(const x in doc.data().huays){
                            const xx = doc.data().huays[x];
                            this.huays[xx] = true;
                        }
                        this.countQTY = doc.data().huays.length;
                    }
                }else{
                    this.countQTY = 0;
                }
            });
            }
    });
    },
    selectNumber(num){
        if(typeof this.countQTY == 'number' && typeof this.QTY == 'number' && this.isShow){
            if(this.huays[num]){
                delete this.huays[num]
                this.countQTY--
            }else if(this.QTY > this.countQTY){
                this.huays[num] = true
                this.countQTY++
            }
            this.rows++;
            this.rows--;
        }
    },
    async confirm() {
        if(Object.keys(this.huays).length <= this.QTY && this.QTY > 0){
            this.buttonLabel = 'loading...';
            this.isDisabled = true;
            const huays = [];
            let lotto = '';
            for(const x in this.huays){
                if(this.huays[x]){
                    huays.push(x);
                    lotto += (lotto)?', '+x:x;
                } 
            }
            firebase.firestore().collection('huays').doc(this.user.key+'-'+moment().tz("Asia/Bangkok").format('YYYY-MM-DD')).set({
                huays,
                userkey: this.user.key,
                updatetime: moment().tz("Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss'),
                date: moment().tz("Asia/Bangkok").format('YYYY-MM-DD'),
                code:this.user.code,
            }).then(async ()=>{
                this.isDisabled = false;
                this.buttonLabel  = 'ยืนยันแทงหวย';
                this.$confirm(
                    {
                        message: `แทงหวยเรียบร้อยแล้วค่ะ 😘`,
                        button: {
                            yes: 'OK'
                        },
                        callback: confirm => {
                        if (confirm) {
                            
                            // this.getUserId()
                        }
                        }
                    }
                )
            });
        }else{
          alert('โปรดตรวจสอบสิทธิ์จำนวนที่แทง');
        }
    },
    renderNumber(row,i){
        const num = ((row-1)*5)+i;
        if(num.toString().length == 1){
            return '0'+num;
        }else{
            return num.toString();
        }
    },
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
  }
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
    
    to {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
}
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
a.button1{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
    text-decoration: none;
    font-size: 15px;
    width: 70px;
}
.active{
    color:#000000 !important;
    background-color:#FFFFFF !important;
    text-decoration: none !important;
}
div.rowClass {
    flex-direction: row;
    justify-content: space-around;
    display: flex;
}
@media all and (max-width:30em){
    a.button1{
        margin:0.4em auto;
    }
}
.btn2 {
    width: 100%;
    font-size: 15px;
    border-radius: 25px;
    color: #fff;
    border: none;
    padding: .7rem 1.5rem;
    min-width: 180px;
    background: linear-gradient(180deg,#91f27f 10%,#00b302);box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 1px 2px hsl(0deg 7% 92% / 24%);
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>
